.text-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh
}

.text-bar {
  width: 50%;
}

.form-label {
    color: white;
    font-size: 1.2em;
}

.submit-button {
  margin-top: 1em;
}

#line-count-response {
  margin-top: 1em;
  color: white;
  font-size: 1.2em;
}

#link-form {
	background-size: cover;
    background-image: url("./images/antoine-barres.jpg");
}


