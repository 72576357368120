/* .main { */
/*   background-image: url("./images/antoine-barres.jpg"); */
/*   background-size: cover; */
/*   min-height: 100vh; */
/*   min-width: 100vh; */
/*   overflow: hidden; */
/* } */

.content-center {
	justify-content: center;
	background-size: cover;
    background-image: url("./images/antoine-barres.jpg");
	align-items: center;
	height: 100vh;
	width: 100%;
	overflow: hidden;
}

.nav-container {
	padding-left: 5em;
	padding-right: 5em;
}

.title-name {
	text-align: center;
	color: white;
}
.title-name h1 {
	font-size: 5em;
	font-weight: 150;
}
.title-name h2 {
	font-weight: 150; 
	color: #ffc107;
};

